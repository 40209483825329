<template>
  <!-- 商品评论页面 -->
  <div class="container">
    <a-row>
      <a-col :span="24" class="right">
        <div class="right-content">
          <!-- 筛选 -->
          <div class="table-page-search-wrapper">
            <a-form layout="inline">
              <a-row :gutter="40">
                <!-- 用户昵称 -->
                <a-col :md="8" :sm="24">
                  <a-form-item label="用户昵称">
                    <a-input v-model="queryParam.customerName" allow-clear placeholder="请输入" />
                  </a-form-item>
                </a-col>
                <a-col :md="16" :sm="24">
                  <a-button type="primary" @click="initTableData">搜索</a-button>
                  <a-button style="margin-left: 8px" @click="() => (queryParam = {})">重置</a-button>
                </a-col>
              </a-row>
            </a-form>
          </div>
          <!-- 表格 -->
          <a-table :columns="columns" :data-source="data" :scroll="{ x: 1300 }" :loading="loading" :pagination="false">
            <!-- 商品图片插槽 -->
            <span slot="goodsImage" slot-scope="text">
              <img :src="text" alt="" />
            </span>
            <!-- 评分插槽 -->
            <span slot="star" slot-scope="text">
              <a-rate :default-value="text" disabled />
            </span>
            <!-- 评论内容插槽 -->
            <span slot="content" slot-scope="text">
              <span class="font-line">{{ text }}</span>
            </span>
            <!-- 操作插槽 -->
            <span slot="action" slot-scope="text, record">
              <a @click="$refs.detailForm.detail(record)">详情</a>
            </span>
          </a-table>
          <!-- 分页组件 -->
          <div style="margin-top: 25px; display: flex; flex-direction: row-reverse">
            <a-pagination
              :pageSize="page.pageSize"
              :current="page.pageNo"
              :total="total"
              :show-total="(total) => `共 ${total} 条`"
              @change="pageChange"
            ></a-pagination>
          </div>
        </div>
      </a-col>
    </a-row>
    <!-- 引入子组件 -->
    <detail-form ref="detailForm"></detail-form>
  </div>
</template>

<script>
import { getCommentsList, commentsDelete } from '@/api/modular/mallLiving/commodity/commodityComments'

import detailForm from './detailForm.vue'

const columns = [
  {
    title: '序号',
    align: 'center',
    width: '100px',
    customRender: (text, record, index) => `${index + 1}`,
  },
  {
    title: '商品名称',
    align: 'center',
    dataIndex: 'goodsName',
  },
  {
    title: '用户昵称',
    dataIndex: 'customerName',
    align: 'center',
  },
  {
    title: '商品图片',
    dataIndex: 'goodsImage',
    align: 'center',
    scopedSlots: { customRender: 'goodsImage' },
  },
  {
    title: '评分',
    dataIndex: 'star',
    align: 'center',
    scopedSlots: { customRender: 'star' },
  },
  {
    title: '评价内容',
    dataIndex: 'content',
    align: 'center',
    scopedSlots: { customRender: 'content' },
  },
  {
    title: '操作',
    align: 'center',
    dataIndex: 'action',
    scopedSlots: { customRender: 'action' },
  },
]

export default {
  data() {
    return {
      columns,
      data: [],
      loading: false,
      star: 3,
      page: {
        pageNo: 2,
        pageSize: 10,
      },
      total: 0,
      queryParam: {},
    }
  },
  components: {
    detailForm,
  },
  mounted() {
    this.initTableData()
  },
  methods: {
    //切换页面
    pageChange(pageNo, pageSize) {
      this.page.pageNo = pageNo
      this.page.pageSize = pageSize
      this.getList()
    },
    initTableData() {
      this.page.pageNo = 1
      this.getList()
    },
    getList() {
      var params = {
        page: this.page,
        queryPrarm: {
          ...this.queryParam,
          goodsId: this.$route.query.goodsId,
          type: 1, //1.普通订单 2.拍图订单 这里写死为1
        },
      }
      getCommentsList(params).then((res) => {
        if (res.success) {
          this.data = res.data.rows
          this.total = res.data.totalRows
        }
      })
    },
    sysItemDelete(record) {
      commentsDelete({ id: record.id, delFlag: '2' }).then((res) => {
        if (res.success) {
          this.getList()
          this.$message.success('该条评价已删除')
        }
      })
    },
  },
}
</script>

<style lang="less" scoped>
.container {
  min-width: 1000px;
}
.title {
  color: rgba(16, 16, 16, 100);
  font-size: 14px;
  font-weight: bold;
}
.right {
  border-radius: 2px;
  background-color: rgba(255, 255, 255, 100);
}
.right-content {
  box-sizing: border-box;
  padding: 10px;
}
.icon {
  font-size: 20px;
  margin: 0 5px;
}
img {
  width: 40px;
  height: 40px;
}
.font-line {
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
}
</style>
